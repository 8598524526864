/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: true, // Change this to true if you want to use the splash screen.
  useCustomCursor: true, // Change this to false if you want the good'ol cursor
  googleTrackingID: "",
};

//Home Page
const greeting = {
  title: "Hello 👋.",
  title2: "AMiR",
  logo_name: "AMiR",
  nickname: "amiranmanesh",
  full_name: "Amirhossein Iranmanesh",
  subTitle:
    "Expert Mobile Developer and Golang Developer. Open Source Enthusiast 🔥. Always learning.",
  resumeLink:
    "https://drive.google.com/file/d/17HCKQFAcqZoSI9jTEsolAB_QuN0vhZSP/view?usp=sharing",
  mail: "mailto:iranmanesh.ah@gmail.com",
};

const socialMediaLinks = {
  /* Your Social Media Link */
  github: "https://github.com/amiranmanesh/",
  linkedin: "https://www.linkedin.com/in/amiranmanesh/",
  gmail: "iranmanesh.ah@gmail.com/",
  gitlab: "https://gitlab.com/amiranmanesh/",
  facebook: "https://www.facebook.com/iranmanesh.ah/",
  twitter: "https://twitter.com/amiranmanesh/",
  instagram: "https://www.instagram.com/amiranmanesh/",
  telegram: "https://t.me/iranmanesh_ah/",
};

const skills = {
  data: [
    {
      title: "Software Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Developing mobile applications using Android & Flutter",
        "⚡ Creating application backend in Go & PHP",
        "⚡ Creating websites with Wordpress",
        "⚡ Thinker & Creative",
        // "⚡ Adept at bringing all team members together to reach a common goal on time and under budget",
        // "⚡ Committed to viable and easily functional app solutions for clients",
      ],
      softwareSkills: [
        {
          skillName: "Android",
          fontAwesomeClassname: "simple-icons:android",
          style: {
            color: "#3DDC84",
          },
        },
        {
          skillName: "Kotlin",
          fontAwesomeClassname: "simple-icons:kotlin",
          style: {
            color: "#756dda",
          },
        },
        {
          skillName: "Java",
          fontAwesomeClassname: "simple-icons:java",
          style: {
            color: "#f89820",
          },
        },
        {
          skillName: "Flutter",
          fontAwesomeClassname: "simple-icons:flutter",
          style: {
            color: "#025597",
          },
        },
        {
          skillName: "Dart",
          fontAwesomeClassname: "simple-icons:dart",
          style: {
            color: "#29B0EE",
          },
        },
        {
          skillName: "Golang",
          fontAwesomeClassname: "simple-icons:go",
          style: {
            color: "#67d0de",
          },
        },
        {
          skillName: "PHP",
          fontAwesomeClassname: "simple-icons:php",
          style: {
            color: "#7277ad",
          },
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            color: "#ea8c11",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "Wordpress",
          fontAwesomeClassname: "simple-icons:wordpress",
          style: {
            color: "#207297",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#149b4e",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#29B0EE",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#f7a00c",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "simple-icons:python",
          style: {
            color: "#376c99",
          },
        },
        {
          skillName: "C++",
          fontAwesomeClassname: "simple-icons:cplusplus",
          style: {
            color: "#02417e",
          },
        },
        {
          skillName: "QT",
          fontAwesomeClassname: "simple-icons:qt",
          style: {
            color: "#3fc74f",
          },
        },
      ],
    },
    // {
    //   title: "Cloud Infra-Architecture",
    //   fileName: "CloudInfraImg",
    //   skills: [
    //     "⚡ Experience working on multiple cloud platforms",
    //     "⚡ Experience hosting and managing websites",
    //     "⚡ Experience with Continuous Integration",
    //   ],
    //   softwareSkills: [
    //     {
    //       skillName: "AWS",
    //       fontAwesomeClassname: "simple-icons:amazonaws",
    //       style: {
    //         color: "#FF9900",
    //       },
    //     },
    //     {
    //       skillName: "Netlify",
    //       fontAwesomeClassname: "simple-icons:netlify",
    //       style: {
    //         color: "#38AFBB",
    //       },
    //     },
    //     {
    //       skillName: "Heroku",
    //       fontAwesomeClassname: "simple-icons:heroku",
    //       style: {
    //         color: "#6863A6",
    //       },
    //     },

    //     {
    //       skillName: "MongoDB",
    //       fontAwesomeClassname: "simple-icons:mongodb",
    //       style: {
    //         color: "#47A248",
    //       },
    //     },
    //     {
    //       skillName: "Docker",
    //       fontAwesomeClassname: "simple-icons:docker",
    //       style: {
    //         color: "#1488C6",
    //       },
    //     },
    //     {
    //       skillName: "GitHub Actions",
    //       fontAwesomeClassname: "simple-icons:githubactions",
    //       style: {
    //         color: "#5b77ef",
    //       },
    //     },
    //   ],
    // },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Master Degree of Artificial Intelligence",
      subtitle: "Science and Research Branch, Izad University, Tehran, Iran",
      logo_path: "uni-azad.png",
      alt_name: "MSC",
      duration: "2021 - Present",
      descriptions: [
        "⚡ I am currently pursuing my Master's degree in Artificial Intelligence.",
        "⚡ I am working on my thesis on Machine Learning and Deep learning. ",
      ],
      website_link: "https://srbiau.ac.ir/",
    },
    {
      title: "Bachelor Degree of Computer Engineering",
      subtitle: "Semnan University, Semnan, Iran",
      logo_path: "semuni-logo.png",
      alt_name: "BSC",
      duration: "2016 - 2020",
      descriptions: [
        "⚡ I have studied core subjects like Data Structures, DBMS, Artificial intelligence (AI), Security, etc.",
        "⚡ I have implemented several projects based on what I've leart under my Computer Engineering course. ",
        "⚡ Teacher Assistant (TA) for 2 years in 2 C++ Programming Courses (Foundation and Advanced)",
      ],
      website_link: "https://ce.semnan.ac.ir/",
    },
  ],
};

const certifications = {
  certifications: [],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "",
  description:
    "I've mostly worked as a freelancer mobile developer or done projects on my own. I love being creative and thinking about new ideas and projects. And now I am working as a Golang developer at Kuknos.org",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Web Development",
      experiences: [
        {
          title: "Golang Developer",
          company: "Kuknos.org",
          company_url: "https://kuknos.org/",
          logo_path: "kuknos.jpeg",
          duration: "10/2021 - Present",
          location: "",
          description:
            "Maintenance and develop microservices using golang and postgresql",
          color: "#743bff",
        },
      ],
    },
    {
      title: "Mobile Development",
      experiences: [
        {
          title: "Freelancer",
          company: "",
          company_url: "",
          logo_path: "freelancer.png",
          duration: "2017 - Present",
          location: "",
          description: "Freelancer Android & Flutter developer",
          color: "#743bff",
        },
      ],
    },
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "me.jpeg",
    description:
      "You can contact me at the places mentioned below. I will try to get back to you as fast as I can. ",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "I don't blog frequently but when I do something awesome, I do try to document it so it can be helpful to others. I write on Twitter.",
    link: "",
    avatar_image_path: "blogs_image.svg",
  },
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects make use of a vast variety of latest technology tools. My best experience is to create Golang Backend Projects, Android Applications, and Flutter Projects. Below are some of my projects.",
  avatar_image_path: "projects_image.svg",
};

const projects = {
  data: [
    {
      id: "6",
      name: "Torob Search Telegram Bot",
      url: "https://t.me/TorobSearchBot",
      description:
        "A Telegram Bot that uses Torob API to search for product prices among the online shops and show the results.",
      languages: [
        {
          name: "MySQL",
          iconifyClass: "logos-mysql",
        },
        {
          name: "PHP",
          iconifyClass: "logos-php",
        },
        {
          name: "Telegram",
          iconifyClass: "logos-telegram",
        },
      ],
    },
    {
      id: "5",
      name: "Radgir App",
      url: "https://radgir.net/",
      description:
        "Smart application to track post (and Tipax) packages with user-friendly Ui & featuring automation package tracking.",
      languages: [
        {
          name: "Firebase",
          iconifyClass: "logos-firebase",
        },
        {
          name: "Kotlin",
          iconifyClass: "logos-kotlin",
        },
        {
          name: "SQLite",
          iconifyClass: "logos-sqlite",
        },
        {
          name: "Android",
          iconifyClass: "logos-android",
        },
      ],
    },
    {
      id: "4",
      name: "HighFollower App",
      url: "https://highfollower.com/",
      description:
        "HighFollower is an Android application that helps to increase Instagram followers and interacting with Instagram APIs in the background.",
      languages: [
        {
          name: "Firebase",
          iconifyClass: "logos-firebase",
        },
        {
          name: "Kotlin",
          iconifyClass: "logos-kotlin",
        },
        {
          name: "Java",
          iconifyClass: "logos-java",
        },
        {
          name: "Android",
          iconifyClass: "logos-android",
        },
      ],
    },
    {
      id: "3",
      name: "Hesabchi App",
      url: "https://hesabchiapp.ir/",
      description:
        "Hesabchi is a tool to manage daily costs and shares between roommates and teams.",
      languages: [
        {
          name: "Firebase",
          iconifyClass: "logos-firebase",
        },
        {
          name: "Kotlin",
          iconifyClass: "logos-kotlin",
        },
        {
          name: "Java",
          iconifyClass: "logos-java",
        },
        {
          name: "Android",
          iconifyClass: "logos-android",
        },
      ],
    },
    {
      id: "2",
      name: "NerkhName App",
      url: "https://b2n.ir/g18277",
      description:
        "Real-time Currency exchange price status Android application.",
      languages: [
        {
          name: "Kotlin",
          iconifyClass: "logos-kotlin",
        },
        {
          name: "Android",
          iconifyClass: "logos-android",
        },
      ],
    },
    {
      id: "1",
      name: "Zooril App",
      url: "https://b2n.ir/p21933",
      description:
        "A smart Application for Zooril smart home kit's clients to control devices.",
      languages: [
        {
          name: "Java",
          iconifyClass: "logos-java",
        },
        {
          name: "Android",
          iconifyClass: "logos-android",
        },
      ],
    },
    {
      id: "0",
      name: "Aapks Store App",
      url: "https://aapks.com/",
      description:
        "AAPKS (Android APKs) is an App store application for android OS. It uses aptoide webservice.",
      languages: [
        {
          name: "Java",
          iconifyClass: "logos-java",
        },
        {
          name: "Android",
          iconifyClass: "logos-android",
        },
      ],
    },
  ],
};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
  projects,
};
